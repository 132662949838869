import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import Client from "../../api/client";
import style from './MarketOverview.module.scss';
import CryptoIcon from "../CryptoIcon";

const backendClient = new Client();

const columns = [
  {
    accessor: 'image',
    Header: 'SYMBOL',
    Cell: (cellData: any) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {cellData.row.original.image && <CryptoIcon source={cellData.row.original.image} symbol={cellData.row.original.symbol} />}
        <div style={cellData.row.original.image ? { marginLeft: '25px' } : { paddingTop: '16px', paddingBottom: '12px' }}>
          <div style={cellData.row.original.image ? {} : { color: '#FFFFFFCC' }}>{cellData.row.original.symbol}</div>
          {cellData.row.original.name && <div style={{ fontSize: '10px' }}>{cellData.row.original.name}</div>}
        </div>
      </div>
    ),
  },
  {
    accessor: 'price',
    Header: 'PRICE',
  },
  {
    accessor: 'percent_change',
    Header: 'CHG%',
    Cell: (cellData: any) => {
      if (parseFloat(cellData.row.original.percent_change) < 0) {
        return (
          <div style={{ color: '#F9423D' }}>{cellData.row.original.percent_change}</div>
        );
      }
      return  <div style={{ color: '#39DF9F' }}>{cellData.row.original.percent_change}</div>;
    },
  },
  {
    accessor: 'change',
    Header: 'CGH',
    Cell: (cellData: any) => {
      if (cellData.row.original.change.includes('-')) {
        return (
          <div style={{ color: '#F9423D' }}>{`-${cellData.row.original.change.replace('-', '')}`}</div>
        );
      }
      return  <div style={{ color: '#39DF9F' }}>{cellData.row.original.change}</div>;
    },
  }
];

const MarketOverview = () => {
  const [summary, setSummary] = useState<any[]>([]);

  const getMarketData = async () => {
    const data: any[] = await (await backendClient.getGlobalMarket()).json();
    const preparedData: any[] = [];
    data.forEach(d => {
      if (!preparedData.find(p => p.category === d.category)) {
        preparedData.push({
          image: '',
          name: '',
          symbol: d.category?.toUpperCase(),
          price: '',
          percent_change: '',
          change: '',
          category: d.category
        });
      }
      preparedData.push({
        image: d.imageUrl,
        name: d.category !== 'forex' && d.titles,
        symbol: d.symbol,
        price: `$${d.price.toFixed(2)}`,
        percent_change: `${d.change_percent.toFixed(2)}%`,
        change: `$${d.change.toFixed(2)}`,
        category: d.category
      });
    });
    setSummary(preparedData);
  };

  useEffect(() => {
    getMarketData();
    const interval = setInterval(() => {
      getMarketData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: summary,
  });

  return (
    <table className={style.table} {...getTableProps()}>
      <thead>
      {headerGroups.map((headerGroup: any) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column: any) => (
            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row: any, i: number) => {
        prepareRow(row)
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell: any) => {
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
          </tr>
        )
      })}
      </tbody>
    </table>
  );
};

export default MarketOverview;