import React, { useState } from "react";
import style from  './InfoPanel.module.scss';
import MarketOverview from "../MarketOverview";

const InfoPanel = () => {
  const [size, setSize] = useState({ x: 610 });

  const handler = (mouseDownEvent: any) => {
    const startSize = size;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    const onMouseMove = (mouseMoveEvent: any) => {
      setSize(currentSize => ({
        x: (startSize.x - startPosition.x + mouseMoveEvent.pageX) < 393 ? 393 : startSize.x - startPosition.x + mouseMoveEvent.pageX,
      }));
    }
    const onMouseUp = () => {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  return (
    <div className={style.panel} style={{ width: size.x, maxHeight: window.innerHeight }}>
      <MarketOverview />
      {/* <AlertsNews /> */}
      <div className={style.handle} onMouseDown={handler} />
    </div>
  );
};

export default InfoPanel;