/* eslint-disable no-undef */

const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  
  class Client {
    url: string;
  
    constructor() {
      if (import.meta.env.VITE_BACKEND_URL === 'https://api.uptick.co/api') {
        this.url = import.meta.env.VITE_BACKEND_URL;
      } else if (import.meta.env.VITE_BACKEND_URL === 'https://api-staging.uptick.co/api') {
        this.url = 'https://staging.uptick.co/api';
      } else {
        this.url = 'http://localhost:3000/api';
      }
    }
  
    refreshToken = (refreshToken: string, accessToken: string, idToken: string) => {
      const url = `${this.url}/refresh_token`;
  
      const body = JSON.stringify({
        token: refreshToken,
      });
  
      return fetch(
        url,
        {
          method: 'POST',
          headers: {
            accessToken,
            idToken,
            ...defaultHeaders,
          },
          body,
        },
      );
    };
  
    objectToQueryString = (object: any) => {
      if (!object || !Object.keys(object).length) return '';
      const checkLast = (index: number, array: any[]) => {
        if (index === array.length - 1) return '';
        return '&';
      };
      return Object.keys(object).reduce((accum, key, index, arr) => {
        if (typeof object[key] === 'string' || typeof object[key] === 'number') return `${accum}${key}=${object[key]}${checkLast(index, arr)}`;
        if (Array.isArray(object[key])) return `${accum}${key}=${object[key].join(',')}${checkLast(index, arr)}`;
        return accum;
      }, '?');
    };
  
    getCurrencies = () => fetch(
      `${this.url}/currencies`,
      {
        method: 'GET',
        headers: defaultHeaders,
      },
    )
  
    getCurrencyProfile = (symbol: string) => fetch(
      `${this.url}/currency_profile?symbol=${symbol}`,
      {
        method: 'GET',
        headers: defaultHeaders,
      },
    )
  
    // eslint-disable-next-line class-methods-use-this
    getEvents = () => {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 365);
  
      return fetch(
        `${this.url}/events?end_ts=${endDate.getTime() / 1000}`,
        {
          method: 'GET',
          headers: defaultHeaders,
        },
      );
    }
  
    getNews = (symbol: string) => {
      const symbolUppercase = symbol.toUpperCase();
      return fetch(
        `${this.url}/news?symbol=${symbolUppercase}`,
        {
          method: 'GET',
          headers: defaultHeaders,
        },
      );
    }
  
    getArticle = (id: string) => fetch(
      `${this.url}/articles/${id}`,
      {
        method: 'GET',
        headers: defaultHeaders,
      },
    )
  
    getOfficialTweets = (symbol: string) => {
      const symbolUppercase = symbol.toUpperCase();
      return fetch(
        `${this.url}/official_tweets?symbol=${symbolUppercase}`,
        {
          method: 'GET',
          headers: defaultHeaders,
        },
      );
    }
  
    getTweets = (symbol: string) => {
      const symbolUppercase = symbol.toUpperCase();
      return fetch(
        `${this.url}/tweets?sym=${symbolUppercase}`,
        {
          method: 'GET',
          headers: defaultHeaders,
        },
      );
    }
  
    getReddit = (symbol: string) => {
      const symbolUppercase = symbol.toUpperCase();
      return fetch(
        `${this.url}/reddit?symbol=${symbolUppercase}`,
        {
          method: 'GET',
          headers: defaultHeaders,
        },
      );
    }
  
    getPortfolioNews = async (params: any) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const result = await fetch(`${import.meta.env.VITE_NEWS_URL}/news/${this.objectToQueryString(params)}`, options).then((res) => res.json()).then((data) => data);
      return result;
    }
  
    getPortfolioTweets = async (params: any) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const result = await fetch(`${import.meta.env.VITE_NEWS_URL}/tweets/${this.objectToQueryString(params)}`, options).then((res) => res.json()).then((data) => data);
      return result;
    }
  
    getPortfolioReddit = async (params: any) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const result = await fetch(`${import.meta.env.VITE_NEWS_URL}/reddit/${this.objectToQueryString(params)}`, options).then((res) => res.json()).then((data) => data);
      return result;
    }
  
    getGlobalMarket = () => {
      const url = 'https://globalmarkets.azurewebsites.net/api/assets';
  
      return fetch(
        url,
        {
          method: 'GET',
          headers: defaultHeaders,
        },
      );
    };
  
    getOmnitableSummary = (exchanges?: string[]) => {
      let url = `${this.url}/omnitable/summary`;
      if (exchanges) {
        const params = new URLSearchParams({
          exchanges: exchanges.join(','),
        });
  
        url += `?${params}`;
      }
  
      return fetch(
        url,
        {
          method: 'GET',
          headers: defaultHeaders,
        },
      );
    }
  
    getOmnitable = (exchanges?: string[]) => {
      let url = `${this.url}/omnitable`;
      if (exchanges) {
        const params = new URLSearchParams({
          exchanges: exchanges.join(','),
        });
  
        url += `?${params}`;
      }
  
      const filters = [{ value: false, field: 'stablecoin', operator: '=' }];
  
      const body = JSON.stringify({
        filters,
      });
  
      return fetch(
        url,
        {
          method: 'POST',
          headers: defaultHeaders,
          body,
        },
      );
    }
  
    // eslint-disable-next-line max-len
    getAlerts = (filters: any[], exchanges?: string[], currency?: string, accessToken?: string, idToken?: string) => {
      let url = `${this.url}/alerts`;
      if (currency) {
        url += `?currency=${currency}`;
      }
      if (exchanges) {
        const params = new URLSearchParams({
          compact: 'true',
          exchanges: exchanges.join(','),
        });
  
        url += `?${params}`;
      }
  
      const body = JSON.stringify({
        filters,
      });
  
      const headers = (accessToken && idToken) ? {
        accessToken,
        idToken,
        ...defaultHeaders,
      } : defaultHeaders;
  
      return fetch(
        url,
        {
          method: 'POST',
          headers,
          body,
        },
      );
    }
  
    getTrendingAlerts = (exchanges?: string[], accessToken?: string, idToken?: string) => {
      let url = `${this.url}/most_alerted`;
      if (exchanges) {
        const params = new URLSearchParams({
          exchanges: exchanges.join(','),
        });
  
        url += `?${params}`;
      }
  
      const headers = (accessToken && idToken) ? {
        accessToken,
        idToken,
        ...defaultHeaders,
      } : defaultHeaders;
  
      return fetch(
        url,
        {
          method: 'GET',
          headers,
        },
      );
    }
  
    getTickers = () => fetch(
      `${this.url}/ticker`,
      {
        method: 'GET',
        headers: defaultHeaders,
      },
    )
  
    getDashboard = () => fetch(
      `${this.url}/user_dashboard`,
      {
        method: 'GET',
        headers: defaultHeaders,
      },
    )
  
    getPortfolioList = (accessToken: string, idToken: string) => fetch(
      `${this.url}/karlie/portfolios?public=1`,
      {
        method: 'GET',
        headers: {
          accessToken,
          idToken,
          ...defaultHeaders,
        },
      },
    )
  
    getStrategyList = (accessToken: string, idToken: string) => fetch(
      `${this.url}/karlie/strategies`,
      {
        method: 'GET',
        headers: {
          accessToken,
          idToken,
          ...defaultHeaders,
        },
      },
    )
  
    getPortfolioById = (accessToken: string, idToken: string, id: string) => fetch(
      `${this.url}/karlie/portfolios/${id}`,
      {
        method: 'GET',
        headers: {
          accessToken,
          idToken,
          ...defaultHeaders,
        },
      },
    )
  
    getPortfolioGainLossById = (accessToken: string, idToken: string, id: string) => fetch(
      `${this.url}/karlie/portfolios/${id}/gain_loss`,
      {
        method: 'GET',
        headers: {
          accessToken,
          idToken,
          ...defaultHeaders,
        },
      },
    )
  
    getDailyIndex = (accessToken: string, idToken: string) => fetch(
      `${this.url}/indexdaily`,
      {
        method: 'GET',
        headers: {
          accessToken,
          idToken,
          ...defaultHeaders,
        },
      },
    )
  
    createPosition = async (accessToken: string, idToken: string, position: any) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
        body: JSON.stringify(position),
      };
      const result = await fetch(`${this.url}/karlie/positions/`, options).then((res) => res.json()).then((data) => data);
      return result;
    };
  
    // eslint-disable-next-line max-len
    editPosition = async (accessToken: string, idToken: string, positionId: string, position: any) => {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
        body: JSON.stringify(position),
      };
      await fetch(`${this.url}/karlie/positions/${positionId}/`, options).then((res) => res.json()).then((data) => data);
    };
  
    deletePosition = async (accessToken: string, idToken: string, positionId: string) => {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
      };
      await fetch(`${this.url}/karlie/positions/${positionId}/`, options);
    };
  
    createPortfolio = async (accessToken: string, idToken: string, portfolio: any) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
        body: JSON.stringify(portfolio),
      };
      const result = await fetch(`${this.url}/karlie/portfolios/`, options).then((res) => res.json()).then((data) => data);
      return result;
    };
  
    createStrategy = async (accessToken: string, idToken: string, strategy: any) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
        body: JSON.stringify(strategy),
      };
      const result = await fetch(`${this.url}/karlie/strategies/`, options).then((res) => res.json()).then((data) => data);
      return result;
    }
  
    // eslint-disable-next-line max-len
    updatePortfolio = async (accessToken: string, idToken: string, portfolio: any, portfolioId: string) => {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
        body: JSON.stringify(portfolio),
      };
      const result = await fetch(`${this.url}/karlie/portfolios/${portfolioId}/`, options).then((res) => res.json()).then((data) => data);
      return result;
    };
  
    // eslint-disable-next-line max-len
    updateStrategy = async (accessToken: string, idToken: string, strategy: any, strategyId: number) => {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
        body: JSON.stringify(strategy),
      };
      const result = await fetch(`${this.url}/karlie/strategies/${strategyId}/`, options).then((res) => res.json()).then((data) => data);
      return result;
    };
  
    // eslint-disable-next-line max-len
    getAllocationsById = async (accessToken: string, idToken: string, id: number, parameters?: any) => {
      let url = `${this.url}/karlie/portfolios/${id}/optimize`;
      if (parameters) {
        const params = new URLSearchParams(parameters);
        url += `?${params}`;
      }
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
      };
      const result = await fetch(url, options).then((res) => res.json()).then((data) => data);
      return result;
    };
  
    getScenarioById = async (accessToken: string, idToken: string, id: number, eventId: number) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
      };
      const result = await fetch(`${this.url}/karlie/portfolios/${id}/simulate?event_id=${eventId}`, options)
        .then((res) => res.json())
        .then((data) => data);
      return result;
    };
  
    getScenarioEvents = async (accessToken: string, idToken: string) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
      };
      const result = await fetch(`${this.url}/karlie/events`, options).then((res) => res.json()).then((data) => data);
      return result;
    };
  
    // eslint-disable-next-line max-len
    getPortfolioDrawdownById = async (accessToken: string, idToken: string, id: number, parameters?: any) => {
      let url = `${this.url}/karlie/portfolios/${id}/drawdown`;
      if (parameters) {
        const params = new URLSearchParams(parameters);
        url += `?${params}`;
      }
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          idToken,
          accessToken,
        },
      };
      const result = await fetch(url, options).then((res) => res.json()).then((data) => data);
      return result;
    };
  
    getMarketSentiment = () => fetch(
      `${this.url}/sentiments`,
      {
        method: 'GET',
        headers: defaultHeaders,
      },
    );
  
    getSectorPerformance = (
      accessToken: string,
      idToken: string,
      sector?: string,
      date?: string,
    ) => {
      let url = `${this.url}/sector_performance`;
  
      const init: {sector?: string, date?: string} = {};
      if (sector) {
        init.sector = sector;
      }
  
      if (date) {
        init.date = date;
      }
  
      if (init) {
        const params = new URLSearchParams(init);
        url += `?${params}`;
      }
  
      const headers = {
        accessToken,
        idToken,
        ...defaultHeaders,
      };
  
      const options = {
        method: 'GET',
        headers,
      };
  
      return fetch(url, options);
    };
  
    getSubsectorPerformance = (
      accessToken: string,
      idToken: string,
      subsector?: string,
      sector?: string,
      date?: string,
    ) => {
      let url = `${this.url}/subsector_performance`;
  
      const init: {subsector?: string, sector?: string, date?: string} = {};
      if (subsector) {
        init.subsector = subsector;
      }
  
      if (sector) {
        init.sector = sector;
      }
  
      if (date) {
        init.date = date;
      }
  
      if (init) {
        const params = new URLSearchParams(init);
        url += `?${params}`;
      }
  
      const headers = {
        accessToken,
        idToken,
        ...defaultHeaders,
      };
  
      const options = {
        method: 'GET',
        headers,
      };
  
      return fetch(url, options);
    };
  
    getUserDetails = (id: string, accessToken: string, idToken: string) => {
      const url = `${this.url}/users/${id}`;
  
      const headers = {
        accessToken,
        idToken,
        ...defaultHeaders,
      };
  
      const options = {
        method: 'GET',
        headers,
      };
  
      return fetch(url, options);
    }
  
    updateUser = (pk: string, accessToken: string, idToken: string, body: string) => fetch(
      `${this.url}/users/${pk}`,
      {
        method: 'PATCH',
        headers: {
          accessToken,
          idToken,
          ...defaultHeaders,
        },
        body,
      },
    )
  
    getExchanges = () => fetch(
      `${this.url}/exchanges`,
      {
        method: 'GET',
      },
    )
  
    verifyTokens = (idToken: string, accessToken: string) => {
      const url = `${this.url}/verify_tokens`;
      const body = JSON.stringify({
        id_token: idToken,
        access_token: accessToken,
      });
  
      const options = {
        method: 'POST',
        headers: defaultHeaders,
        body,
      };
  
      return fetch(url, options);
    }
  
  
    requestVerificationCode = (email: string) => {
      const url = `${this.url}/forgot_password`;
  
      const requestBody = {
        username: email,
      };
  
      const headers = {
        ...defaultHeaders,
      };
  
      const body = JSON.stringify(requestBody);
  
      return fetch(url, {
        method: 'POST',
        headers,
        body,
      });
    }
  
    resetPassword = (email: string, password: string, code: string) => {
      const url = `${this.url}/change_password`;
  
      const requestBody = {
        username: email,
        password,
        code,
      };
  
      const headers = {
        ...defaultHeaders,
      };
  
      const body = JSON.stringify(requestBody);
  
      return fetch(url, {
        method: 'POST',
        headers,
        body,
      });
    }
  
    cancelSubscription = (accessToken: string, idToken: string) => {
      const url = `${this.url}/cancel_subscription`;
  
      const headers = {
        accessToken,
        idToken,
        ...defaultHeaders,
      };
  
      return fetch(url, {
        method: 'POST',
        headers,
      });
    }
  
    getPayment = (accessToken: string, idToken: string) => {
      const url = `${this.url}/payment`;
      const headers = {
        accessToken,
        idToken,
        ...defaultHeaders,
      };
      const options = {
        method: 'GET',
        headers,
      };
      return fetch(url, options);
    }
  
    validateCoupon = (coupon: string, accessToken: string, idToken: string) => {
      const url = `${this.url}/validate_coupon`;
      const body = JSON.stringify({
        coupon: coupon.toUpperCase(),
      });
  
      const headers = {
        accessToken,
        idToken,
        ...defaultHeaders,
      };
  
      return fetch(url, {
        method: 'POST',
        headers,
        body,
      });
    }
  
    deleteAccount = (accessToken: string, idToken: string) => {
      const url = `${this.url}/account`;
  
      const headers = {
        accessToken,
        idToken,
        ...defaultHeaders,
      };
  
      return fetch(url, {
        method: 'DELETE',
        headers,
      });
    }
  
    connectWebSocket = (symbol: string) => {
      const url = `${import.meta.env.VITE_MARKETDATA_WS_URL}/feed/?tickers=binance:${symbol}`;
      return new WebSocket(url);
    }
  }
  
  export default Client;